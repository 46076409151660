import { AuthLayout } from "components/Global/AuthLayout";
import GlobalLayout from "layouts/Global";
import { Home, Login, Sales } from "pages";
// import Roles from "pages/settings/roles";
import Settings from "pages/settings/settings";
// import Users from "pages/settings/users";

import { createBrowserRouter } from "react-router-dom";
// import Subcription from "pages/settings/subscription";
import IntegrationPage from "pages/settings/Integration";
import CheckWarrantyPage from "pages/CheckWarranty";
import ConnectedApps from "pages/settings/Integration/ConnectedApps";
import AccountingTable from "components/settings/Integration/Accouting";
import AccessDeniedPage from "pages/AccessDeniedPage";
// import CallbackComponent from "components/settings/Integration/Accouting/AccountingCallback";
import QuickbookDesktop from "components/Global/QuickbookDesktop";
import PageNotFoundPage from "pages/PageNotFound";
import UsersPage from "pages/users";
import ResetPassword from "pages/ResetPassword";
// import Roles from "pages/settings/roles";
import ForgotPassword from "pages/ForgotPassword";
import Rules from "pages/settings/rules";
// import Configurations from "pages/settings/Configurations";

const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    children: [
      {
        path: "/",
        element: <GlobalLayout />,
        children: [
          {
            index: true,
            path: "/",
            element: <Home />,
          },
          {
            path: "sales",
            element: <Sales />,
          },
        ],
      },
      {
        path: "/settings",
        element: <Settings />,
        children: [
          {
            path: "integrations",
            element: <IntegrationPage />,
            children: [
              {
                index: true,
                path: "connectedApps",
                element: <ConnectedApps />,
              },
              {
                path: "accounting",
                element: <AccountingTable />,
              },

              {
                path: "qbd",
                element: <QuickbookDesktop />,
              },
            ],
          },
          {
            path: "users",
            element: <UsersPage />,
          },
          // {
          //   path: "roles",
          //   element: <Roles />,
          // },
          {
            path: "warranty-rules",
            element: <Rules />,
          },
        ],
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        element: <Login />,
        path: "/login",
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/access-denied",
        element: <AccessDeniedPage />,
      },
    ],
  },
  {
    path: "/check-warranty",
    element: <CheckWarrantyPage />,
  },
  {
    path: "*",
    element: <PageNotFoundPage />,
  },
]);

export default router;
