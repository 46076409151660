import { FC } from "react";
import {
  SearchOutlined,
  CloseCircleOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, Row, Select, Tooltip } from "antd";
import styles from "./index.module.scss";
import { SearchAndFilterProps } from "./types";

const SearchAndFilter: FC<SearchAndFilterProps> = (props) => {
  const {
    performSearchHandler,
    handleExtendWarranty,
    searchValue,
    searchFieldsSelectHandler,
    resetButtonHandler,
    searchFields,
    menuName,
    options,
  } = props;

  const searchWarrantyRuleStyle =
    menuName === "Warranty-Rules" ? { paddingLeft: 0 } : {};

  return (
    <div className={styles["search-filter"]}>
      <div
        className={styles["search-filter__wrapper"]}
        style={searchWarrantyRuleStyle}
      >
        <Row
          gutter={[8, 8]}
          align="middle"
          justify="start"
        >
          {/* Input Field */}
          <Col
            xs={24}
            sm={10}
            md={8}
            lg={7}
            xl={6}
          >
            <Input
              className={styles["search-filter__search"]}
              placeholder="Search..."
              suffix={<SearchOutlined />}
              onChange={performSearchHandler}
              value={searchValue}
              size="large"
              disabled={searchFields.length === 0}
            />
          </Col>

          {/* Select Field */}
          <Col
            xs={24}
            sm={10}
            md={8}
            lg={7}
            xl={6}
          >
            <Select
              className={styles["search-filter__select"]}
              mode="multiple"
              maxTagCount="responsive"
              maxTagPlaceholder={(omittedValues) => {
                console.log(omittedValues);

                return (
                  <Tooltip
                    overlayStyle={{ pointerEvents: "none" }}
                    title={omittedValues.map(({ label }) => label).join(", ")}
                  >
                    <span>+{omittedValues?.length} more..</span>
                  </Tooltip>
                );
              }}
              allowClear
              style={{ width: "100%" }}
              placeholder="Search on fields"
              onChange={searchFieldsSelectHandler}
              options={options}
              value={searchFields}
            />
          </Col>

          {/* Reset Button */}
          <Col
            xs={24}
            sm={4}
            md={4}
            lg={3}
            xl={2}
          >
            <Button
              type="primary"
              className={styles["search-filter__reset"]}
              icon={<CloseCircleOutlined />}
              onClick={resetButtonHandler}
              block
            >
              Reset
            </Button>
          </Col>

          {/* Conditionally Render Extend Warranty Button */}
          {menuName === "Sales" || menuName === "Warranty-Rules" ? (
            <Col
              xs={24}
              sm={4}
              md={4}
              lg={3}
              xl={2}
            >
              <Button
                type="primary"
                className={styles["search-filter__reset"]}
                onClick={
                  handleExtendWarranty && (() => handleExtendWarranty(true))
                }
              >
                {" "}
                <FileAddOutlined />
                {menuName === "Warranty-Rules"
                  ? "Import Rules"
                  : "Import Extended Warranty"}
              </Button>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </div>
    </div>
  );
};

export default SearchAndFilter;
