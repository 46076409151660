import { Col, Row } from "antd";
import { Header } from "components/Global";
import SettingsLayout from "layouts/Settings";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { CloseSvg } from "utils/svgs";
import styles from "./index.module.scss";
import { useSelector } from "react-redux";

export default function Settings() {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const pathSegments = pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];

  // State to store the selected sidebar
  const [selectedSidebar, setSelectedSidebar] = useState<string>(lastSegment);

  // Function to handle sidebar changes
  const sideBarChangeHandler = (selectedValue: any) => {
    // Update the selected sidebar based on the value
    setSelectedSidebar(selectedValue.key);
    // Navigate to the selected sidebar's route
    navigate(
      selectedValue.key === "integrations"
        ? "/settings/integrations/connectedApps"
        : selectedValue.key
    );
  };

  // Function to set the initial selected sidebar
  const initialFunction = () => {
    const splitArray = pathname.split("/");
    let key = splitArray[splitArray.length - 1];

    // Check if the pathname starts with '/settings/integrations' and set the key accordingly
    if (pathname.startsWith("/settings/integrations")) {
      key = "integrations";
    }

    // Set the selected sidebar
    setSelectedSidebar(key);
  };

  const profileData = useSelector((state: any) => state?.userProfile?.data);
  const roleName = profileData?.companyRoles[0]?.role?.roleName;

  useEffect(() => {
    if (
      !(roleName === "Company Admin" || roleName === "Service Center Employee")
    ) {
      navigate("/");
    }
  }, []);

  // Call the initial function when the component is mounted or when the window location changes
  useEffect(() => {
    initialFunction();
  }, [window.location.href]);

  return (
    <>
      <Header />
      <Row
        className={styles["header"]}
        justify="space-between"
        align="middle"
      >
        <Col>
          <h4 className={styles["header--heading"]}>Settings</h4>
        </Col>
        <Col>
          <div
            className={styles["header--close-icon"]}
            onClick={() => {
              navigate("/");
            }}
          >
            <CloseSvg />
          </div>
        </Col>
      </Row>
      <SettingsLayout
        onSideBarChange={sideBarChangeHandler}
        selectedSidebar={selectedSidebar}
      >
        <Outlet />
      </SettingsLayout>
    </>
  );
}
