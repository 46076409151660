import React, { useState } from "react";
import { Form, Input, Button, Table, message } from "antd";
import "./index.css";
import { postApi } from "redux/apis";
import { Tag } from "antd"; // Ensure you import Tag from antd
import dayjs from "dayjs"; // Import dayjs for date formatting

const WarrantyCheck: React.FC = () => {
  const [form] = Form.useForm();
  const [warrantyData, setWarrantyData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "Serial Number",
      dataIndex: "serialNumber",
      key: "serialNumber",
    },
    {
      title: "Product Name",
      dataIndex: "itemName",
      key: "productName",
    },
    {
      title: "Product Description",
      dataIndex: "itemDesc",
      key: "itemDesc",
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (text: string) => dayjs(text).format("MM-DD-YYYY"), // Format date
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
      render: (text: string) => dayjs(text).format("MM-DD-YYYY"), // Format date
    },
    {
      title: "Status",
      dataIndex: "isExpired",
      key: "status",
      render: (isExpired: boolean) => (
        <Tag color={isExpired ? "red" : "green"}>
          {isExpired ? "Expired" : "In Warranty"}
        </Tag>
      ),
    },
  ];

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const response = await postApi(`/productWarrantyDetails/check-warranty`, {
        serialNumber: values.serialNumber,
      });
      if (response.data) {
        console.log(response.data);
        setWarrantyData([response.data.data]); // Assuming API returns a single object
        message.success(response.data.message);
      } else {
        setWarrantyData([]);
        message.error("No warranty details found.");
      }
    } catch (error: any) {
      setWarrantyData([]);
      console.log(error);
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="warranty-check">
      <main className="main">
        <img
          src={"/assets/3nStarLogo.png"}
          alt="3nStar Logo"
          className="logo"
        />
        <h1 className="title">3nStar Warranty Check</h1>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className="form"
        >
          <Form.Item
            name="serialNumber"
            rules={[
              { required: true, message: "Please enter your serial number" },
            ]}
          >
            <Input
              id="serial-number"
              name="serial-number"
              placeholder="Serial Number"
              className="input"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="button"
            >
              Check Warranty
            </Button>
          </Form.Item>
        </Form>

        <div className="table-container">
          <h2 className="subtitle">Product Details</h2>
          <Table
            dataSource={warrantyData}
            columns={columns}
            rowKey="serialNumber"
            pagination={false}
            bordered
            className="ant-table"
            locale={{
              emptyText:
                "No data available. Please enter a serial number and click 'Check Warranty'.",
            }}
          />
        </div>

        <div className="warranty-info">
          <div className="card">
            <h2 className="card-title">Warranty Policy</h2>
            <p>
              All our products include a limited warranty covering manufacturer
              defects. If your product is within the warranty period, it will be
              repaired at no cost.
            </p>
          </div>
          <div className="card">
            <h2 className="card-title">Getting Support</h2>
            <p>
              If your product is under warranty, please contact our support team
              at <a href="mailto:support@3nStar.com">support@3nStar.com</a>.
              Have your model and serial number ready when reaching out.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default WarrantyCheck;
