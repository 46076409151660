import { FORMDATA } from "constants/Data";
import { FC, useEffect } from "react";
import styles from "./index.module.scss";
import { IntegrationLayoutProps } from "./types";
import IntegrationSidebar from "components/Global/IntegrationSidebar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const IntegrationLayout: FC<IntegrationLayoutProps> = (props) => {
  const { newIntegrationMenuItems } = FORMDATA;
  const { children, onSideBarChange, selectedSidebar } = props;

  const profileData = useSelector((state: any) => state?.userProfile?.data);
  const roleName = profileData?.companyRoles[0]?.role?.roleName;

  const navigate = useNavigate();
  useEffect(() => {
    if (roleName === "Employee") {
      navigate("/");
    }
  }, []);

  // JSX
  return (
    <div className={styles["settings-layout"]}>
      <div className={styles["settings-layout__wrapper"]}>
        <IntegrationSidebar
          items={newIntegrationMenuItems}
          handleSidebar={onSideBarChange}
          selectedSidebar={selectedSidebar as string}
        />
        <div className={styles["settings-layout__body"]}>{children}</div>
      </div>
    </div>
  );
};

export default IntegrationLayout;
