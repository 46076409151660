import { createSlice } from "@reduxjs/toolkit";
import {
  getWarrantyRulesActionTable,
  editWarrantyRulesActionTable,
  addWarrantyRulesActionTable,
  paginateWarrantyRulesTable,
  deleteWarrantyRulesActionTable,
  editDefaultWarrantyMonthRuleActionTable,
} from "redux/action/warrantyRulesActions";
import { toastText } from "utils/utils";

const initialState: any = {
  data: null,
  fistTimeFetchLoading: true,
  total: 0,
  isLoading: false,
  count: 0,
  error: null,
};

const warrantyRulesTableSlice = createSlice({
  name: "warrantyRulesTable",
  initialState,
  reducers: {
    clearRedux: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    // Get All Roles
    builder.addCase(getWarrantyRulesActionTable.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getWarrantyRulesActionTable.fulfilled, (state, action) => {
      state.data = action?.payload.rules;
      state.count = action?.payload?.totalRecords;
      state.isLoading = false;
      state.fistTimeFetchLoading = false;
    });
    builder.addCase(
      getWarrantyRulesActionTable.rejected,
      (state, action: any) => {
        state.error = action.payload?.data;
        if (action?.payload?.responseStatus !== 401) {
          toastText(action?.payload?.message, "error");
        }
        state.isLoading = false;
        state.fistTimeFetchLoading = false;
      }
    );

    //paginate role
    builder.addCase(paginateWarrantyRulesTable.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload;
    });

    // Add New Role
    builder.addCase(addWarrantyRulesActionTable.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(addWarrantyRulesActionTable.fulfilled, (state, action) => {
      state.isLoading = false;
      console.log(" action.payload", action.payload);
      state.data = [...state.data, action.payload.data];
      state.count = state.count + 1;
      toastText(action?.payload?.message, "success");
    });
    builder.addCase(
      addWarrantyRulesActionTable.rejected,
      (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
        if (action?.payload?.responseStatus !== 401) {
          toastText(action?.payload?.message, "error");
        }
      }
    );

    // Edit Role
    builder.addCase(editWarrantyRulesActionTable.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(editWarrantyRulesActionTable.fulfilled, (state, action) => {
      state.isLoading = false;
      console.log("action.payload", action.payload);

      const updatedData = action.payload.editData;
      state.data = state.data.map((rule: any) => {
        if (rule?.id === updatedData.id) {
          return {
            ...rule,
            effectiveDate: updatedData?.effectiveDate || rule?.effectiveDate,
            comments: updatedData?.comments || rule?.comments,
            countryISOCode: updatedData?.countryISOCode || rule?.countryISOCode,
            warrantyMonth: updatedData?.warrantyMonth || rule?.warrantyMonth,
            product: updatedData?.product || rule?.product,
            productCategory:
              updatedData?.productCategory || rule?.productCategory,
            customer: updatedData?.customer || rule?.customer,
          };
        }
        return rule;
      });

      toastText("Rule updated successfully", "success");
    });
    builder.addCase(
      editWarrantyRulesActionTable.rejected,
      (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
        if (action?.payload?.responseStatus !== 401) {
          toastText(action?.payload?.message, "error");
        }
      }
    );

    // Edit Month Rule
    builder.addCase(
      editDefaultWarrantyMonthRuleActionTable.pending,
      (state) => {
        state.isLoading = true;
        state.error = null;
      }
    );
    builder.addCase(
      editDefaultWarrantyMonthRuleActionTable.fulfilled,
      (state, action) => {
        state.isLoading = false;
        console.log("action.payload", action.payload);

        const updatedData = action.payload.editData;

        state.data = updatedData;

        toastText("Default Month Rule updated successfully", "success");
      }
    );
    builder.addCase(
      editDefaultWarrantyMonthRuleActionTable.rejected,
      (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
        if (action?.payload?.responseStatus !== 401) {
          toastText(action?.payload?.message, "error");
        }
      }
    );

    // Delete Role
    builder.addCase(deleteWarrantyRulesActionTable.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(
      deleteWarrantyRulesActionTable.fulfilled,
      (state, action) => {
        console.log(state, action);
        state.isLoading = false;
        console.log(state.data, action.payload);
        state.data = state?.data?.filter(
          (item: any) => item?.id !== action?.payload?.id
        );
        state.count = state.count - 1;
        toastText(action?.payload?.response?.message, "success");
      }
    );
    builder.addCase(
      deleteWarrantyRulesActionTable.rejected,
      (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload?.response;
        if (action?.payload?.responseStatus !== 401) {
          toastText(action?.payload?.message, "error");
        }
      }
    );
  },
});

export default warrantyRulesTableSlice;
export const { clearRedux } = warrantyRulesTableSlice.actions;
